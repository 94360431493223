const routes = [
  {
    path: '/feature-toggles',
    name: 'featuretoggles',
    meta: {
      title: 'Toggle Configuration',
      pageH1: 'Toggle Configuration',
      requiresAuth: true,
      accessObject: [
        {
          accessTypeFunction: null,
          designations: null,
        },
      ],
    },
    beforeEnter() {
    },
    components: {
      content: () => import('../views/FeatureToggles.vue'),
    },
  },
];

export default routes;
