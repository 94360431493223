import { useAuthenticateStore } from '../store/pinia/authenticate';

const unmatched = '/:pathMatch(.*)*';

const routes = [
  // ####################### Handling 404 Requests ####################### //
  {
    path: '/unable-to-access',
    name: 'noAccess',
    components: {
      content: () => import('../views/NotAuthorizedToViewPage.vue'),
    },
    meta: {
      title: 'You do not have access to the requested page',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
    props: true,
  },
  {
    path: '/access-issue',
    name: 'accessIssue',
    components: {
      unguardedContent: () => import('../views/AccessRestricted.vue'),
    },
    meta: {
      title: 'Issue accessing your account',
      requiresAuth: false,
    },
    props: true,
  },
  {
    path: '/404',
    name: 'badRequest404',
    components: {
      unguardedContent: () => import('../views/BadRequest404.vue'),
    },
    meta: {
      title: 'The page you are trying to access doesn\t exist',
    },
  },
  {
    path: '/page-not-found',
    name: 'authenticated404',
    components: {
      content: () => import('../views/BadRequest404.vue'),
    },
    meta: {
      title: 'The page you are trying to access doesn\t exist',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
  },
  {
    path: unmatched,
    name: 'badRequest',
    beforeEnter(to, from, next) {
      const authenticateStore = useAuthenticateStore();
      if (authenticateStore.account) {
        next({ name: 'authenticated404' }); // Redirect to authenticated 404
      } else {
        next({ name: 'badRequest404' }); // Redirect to unauthenticated 404
      }
    },
  },
];

export default routes;
