import { defineStore } from 'pinia';
import {
    masqueradeAsUser,
    revertMasquerade,
    loadUser,
    clearUser,
    getMasqueradeTargets,
    getAllUserDesignations,
    getAllGroupDesignations,
    getAllUserTypes,
} from '../../services/masquerade';
import { logEvent }from '../../helpers/logging';
import { useAuthenticateStore } from '../pinia/authenticate';

export const useMasqueradeStore = defineStore('masquerade', {
    state: () => ({
        _originalUser: false,
        // this is the same value as the azureObjectId in the userDetails object
        _masqueradeId: (() => {
            try {
                const authenticateStore = useAuthenticateStore();
                return authenticateStore.localStorageObject.masqueradeId;
            } catch (error) {
                return null;
            }
        })(),
        _userDetails: {
            userId: null,
            userName: '',
            fullName: '',
            emailAddress: '',
            azureObjectId: '',
            userAccess: {
                designationGroupIds: [],
                userTypeId: null,
                serviceCoordinatorIds: [],
                assignedToIds: [],
                hrLiaisonIds: [],
                implementationManagerIds: [],
                implementationCoordinatorIds: [],
            },
        },
    }),
    getters: {
        originalUser: (state) => state._originalUser,
        masqueradeId(state) {
            let { _masqueradeId } = state;
            if (_masqueradeId === null || _masqueradeId === undefined) {
                const authenticateStore = useAuthenticateStore();
                _masqueradeId = authenticateStore.localStorageObject.masqueradeId;
            }
            return _masqueradeId;
        },
        userDetails: (state) => state._userDetails,
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        async masqueradeAsUser(event) {
            return masqueradeAsUser(event)
                .then((response) => {
                    if (response) {
                        this.setMasqueradeId(event.masqueradeId);
                        this._originalUser = true;
                        loadUser();
                        return response.data;
                    } else {
                        this.setMasqueradeId(null);
                        this._originalUser = false;
                        clearUser();
                        return [];
                    }
                })
                .catch((e) => {
                    this.setMasqueradeId(null);
                    this._originalUser = false;
                    clearUser();
                    throw e;
                });
        },
        // eslint-disable-next-line no-unused-vars
        async revertMasquerade(event) {
            return revertMasquerade(event)
                .then((response) => {
                    if (response) {
                        this.setMasqueradeId(null);
                        this._originalUser = false;
                        clearUser();
                    return response.data;
                    } else {
                        this.setMasqueradeId(null);
                        this._originalUser = false;
                        clearUser();
                        return [];
                    }
                })
                .catch((e) => {
                    this.setMasqueradeId(null);
                    this._originalUser = false;
                    clearUser();
                    throw e;
                });
        },
        // eslint-disable-next-line no-unused-vars
        async loadUser(event) {
            return loadUser(event)
                .then((response) => {
                    if (response) {
                        logEvent({
                            type: 'verbose',
                            message: `Masquerade User Retrieved: ${JSON.stringify(response)}`,
                            functionName: 'actions/loadUser',
                            fileName: 'store/pinia/masquerade.js',
                        });
                        this._userDetails.userName = response.userName;
                        this._userDetails.userId = response.userId;
                        this._userDetails.fullName = response.fullName;
                        this._userDetails.azureObjectId = response.azureObjectId;
                        this._userDetails.emailAddress = response.emailAddress;
                        this._userDetails.userAccess.userTypeId = response.userTypeId;
                        this._userDetails.userAccess.designationGroupIds = response.designationGroupIds;
                        this._userDetails.userAccess.serviceCoordinatorIds = response.serviceCoordinatorIds;
                        this._userDetails.userAccess.assignedToIds = response.assignedToIds;
                        this._userDetails.userAccess.hrLiaisonIds = response.hrLiaisonIds;
                        this._userDetails.userAccess.implementationManagerIds = response.implementationManagerIds;
                        this._userDetails.userAccess.implementationCoordinatorIds = response.implementationCoordinatorIds;
                    } else {
                        // Undefined means we have invalid auth
                        // Need to do something here
                    }
                })
                .catch((e) => {
                    throw e;
                });
        },
        // eslint-disable-next-line no-unused-vars
        async clearUser(event) {
            return clearUser(event)
                .then((response) => {
                    if (response) {
                        this._userDetails.userName = response.userName;
                        this._userDetails.userId = response.userId;
                        this._userDetails.fullName = response.fullName;
                        this._userDetails.azureObjectId = response.azureObjectId;
                        this._userDetails.emailAddress = response.emailAddress;
                        this._userDetails.userTypeId = response.userTypeId;
                        this._userDetails.designationGroupIds = response.designationGroupIds;
                        this._userDetails.serviceCoordinatorIds = response.serviceCoordinatorIds;
                        this._userDetails.assignedToIds = response.assignedToIds;
                        this._userDetails.hrLiaisonIds = response.hrLiaisonIds;
                        this._userDetails.implementationManagerIds = response.implementationManagerIds;
                        this._userDetails.implementationCoordinatorIds = response.implementationCoordinatorIds;
                        localStorage.clear();
                        sessionStorage.clear();
                    } else {
                    // Logout failed, so display message to user
                    }
                })
                .catch((e) => {
                    throw e;
                });
        },
        // eslint-disable-next-line no-unused-vars
        async getMasqueradeTargets(event) {
            return getMasqueradeTargets(event)
                .then((response) => {
                    return response;
                })
                .catch((e) => {
                    throw e;
                });
        },
                // eslint-disable-next-line no-unused-vars
        async getAllUserDesignations(event) {
            return getAllUserDesignations(event);
        },
        // eslint-disable-next-line no-unused-vars
        async getAllUserTypes(event) {
            return getAllUserTypes(event)
                .then((response) => {
                    return response.data;
                })
                .catch((e) => {
                    throw e;
                });
        },

        async getAllGroupDesignations(event) {
            return getAllGroupDesignations(event)
                .then((response) => {
                    return response;
                })
                .catch((e) => {
                    throw e;
                });
        },
                        
        async setMasqueradeId(event) {
            logEvent({
              type: 'verbose',
              message: `MasqueradeId Created, ${event}`,
              functionName: 'SET_MASQUERADE_ID',
              fileName: 'store/pinia/masquerade.js',
            });
            const authenticateStore = useAuthenticateStore();
            this._masqueradeId = event;
            const storageObject = authenticateStore.localStorageObject;
            storageObject.masqueradeId = event;
            authenticateStore.setLocalStorageObject(storageObject);
          },
    },
});