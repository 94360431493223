const providerrelationsRoutes = [
    {
        path: '/providerrelations',
        name: 'pr',
        beforeEnter() {
        },
        components: {
          content: () => import('../views/ProviderRelationsDashboard.vue'),
        },
        meta: {
          title: 'PR',
          requiresAuth: true,
          accessObject:null,
        },
    },
];

export default providerrelationsRoutes;

