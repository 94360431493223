const routes = [
  {
    path: '/memberadvocates',
    name: 'memberadvocates',
    redirect: { name: 'member-advocated-dashboard' },
    components: {
      content: () => import('../views/MemberAdvocates.vue'),
    },
    meta: {
      title: 'Member Advocates',
      PageH1: 'Member Advocates',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
    children: [
      {
        path: '/memberadvocates/dashboard',
        name: 'member-advocated-dashboard',
        components: {
          memberAdvocatesContent: () => import('@components/partials/members/MemberAdvocatesDashboard.vue'),
        },
        meta: {
          title: 'Member Advocates',
          pageH1: 'Member Advocates - Scoreboard',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
        },
      }],
  },
];

export default routes;
