// ##################### Authentication Details - Start ##################### //
// Azure allows two different types of login patterns when logging in, logging out, 
// and requesting a new token - when the token can't be retrieved silently
//
// 1. popup - This will keep the app on the same screen and popup a login dialog
// 2. redirect - This will take the app to a login page and then back to the app
//
// There is code below that is commented out for the popup pattern
// We are using the redirect pattern at this point because it's consistent with
// the current Compass application, and it doesn't run the risk of being
// blocked by popup blockers.
// ##################### Authentication Details - End ##################### //
import { InteractionRequiredAuthError, PublicClientApplication, BrowserAuthError } from '@azure/msal-browser';
import { config, scopes, graphScopes } from '../auth/msalConfig';
import { logEvent } from '../helpers/logging';

let msalInstance: PublicClientApplication;

// Initialize msalInstance
export async function initializeMsalInstance() {
  msalInstance = new PublicClientApplication(await config());
}
// Value used for auth pulled from Azure Dashboard  
export async function initialize(client) {
  // start msal
  await msalInstance.initialize();
  // used when doing redirect rather than popup
  await msalInstance.handleRedirectPromise();

  // hook into application router
  if (client) {
    msalInstance.setNavigationClient(client);
  }

  // grab and set account if in session
  const accounts = msalInstance.getAllAccounts();
  if (accounts?.length) {
    setAccount(accounts[0]);
  }

  // return any active account
  return msalInstance.getActiveAccount();
}

// Login call to get account object from Azure AD
export async function login() {
  const authConfig = await config();
  const request = {
    redirectUri: authConfig.auth.redirectUri,
    scopes: await scopes(),
  };
  await msalInstance.initialize();
  try{
    await msalInstance.loginRedirect(request);
  }
  catch(e){
    if (e.errorCode === 'interaction_in_progress') {
      reset();
      return login();
    }
    throw e;
  }
}

// Logout call to end and clear session
export async function logout() {
  return msalInstance
  .logoutRedirect({
      postLogoutRedirectUri: '/',      
    });
}

// Retrieves a token from Azure API
// It first attempts to retrieve silently if active session
// Otherwise, it will redirect the screen to the Microsoft
// Online login to reauthenticate
export async function getToken() {
  const request = {
    scopes: await scopes(),
  };

  try {
    const tokenResult = await msalInstance.acquireTokenSilent(request);
    return tokenResult.accessToken;
  }
  catch(e){
    if (e instanceof InteractionRequiredAuthError) {
      return await msalInstance.acquireTokenRedirect(request);
      // return msalInstance.acquireTokenPopup(request);
      // The BrowserAuthError is thrown when the token is invalid, so we 
      // need to login again when this happens
    } else if (e instanceof BrowserAuthError) {
      await login();
    }
    throw e;
  }
}

// The Graph API requires a token with different scopes than
// the token retrieved for calling our own API
// This function retrieves a Graph API specific token
export async function getGraphToken() {
  const request = {
    scopes: await graphScopes(),
  };

  try {
    const tokenResult = await msalInstance.acquireTokenSilent(request);
    return tokenResult.accessToken;
  }
  catch (e) {
    if (e instanceof InteractionRequiredAuthError) {
      return msalInstance.acquireTokenRedirect(request);
    }
    throw e;
  }
}

// This sets the active account specific to the MSAL library
export async function setAccount(account) {
  msalInstance.setActiveAccount(account);
  logEvent({
    type: 'verbose',
    message: `Account Set: ${JSON.stringify(account)}`,
    functionName: 'setAccount',
    fileName: '/services/authenticate.js',
  });
  return account;
}

// Called when needing to clear the session storage after a user
// logs out
export async function reset() {
  sessionStorage.clear();
}

export async function refreshToken() {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw new Error('No active account found');
  }

  const request = {
    account: account,
    scopes: await scopes(),
  };

  try {
    const authenticationResult = await msalInstance.acquireTokenSilent(request);
    await setAccount(authenticationResult.account);
    return msalInstance.getActiveAccount();
  } catch (e) {
    if (e instanceof InteractionRequiredAuthError || e instanceof BrowserAuthError) {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenRedirect(request);
    } else {
      throw e;
    }
  }
}

export default {
  login,
  logout,
  initialize,
  initializeMsalInstance,
  getToken,
  getGraphToken,
  refreshToken,
};
