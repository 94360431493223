import { defineStore } from 'pinia';
import { getApplicationSettings } from '../../services/applicationSettings';

export const useApplicationSettingsStore = defineStore('applicationSettings', {
    state: () => ({
        _nodeEnv: null,
        _compassEnv: '',
        _appAuth: {
            authAuthority: '',
            authClientId: '',
            authGraphUrl: '',
            authRedirect: '',
            authScope: '',
            authScopeGraph: '',
        },
        _appUrls: {
            appBaseApiHost: `${window.location.origin}/api`,
            appBaseLegacyHost: '',
            appBasePortalHost: '',
        },
        _sentryConfiguration: {
            enabled: false,
            dsn: '',
            sampleRate: null,
        },
    }),
    getters: {
        nodeEnv: (state) => state._nodeEnv,
        compassEnv: (state) => state._compassEnv,
        appAuth: (state) => state._appAuth,
        appUrls: (state) => state._appUrls,
        sentryConfiguration: (state) => state._sentryConfiguration,
    },
    actions: {
        // eslint-disable-next-line no-unused-vars
        async getApplicationSettings(event) {
            return await getApplicationSettings(event)
                .then((response) => {
                    if (response && response.data) {
                        this._nodeEnv = response.data.nodeEnv;
                        this._compassEnv = response.data.compassEnv;
                        this._sentryConfiguration.dsn = response.data.sentryConfiguration.dsn;
                        this._sentryConfiguration.enabled = response.data.sentryConfiguration.enabled;
                        this._sentryConfiguration.sampleRate = response.data.sentryConfiguration.sampleRate;
                        this._appUrls.appBaseApiHost = response.data.appUrls.appBaseApiHost;
                        this._appUrls.appBaseLegacyHost = response.data.appUrls.appBaseLegacyHost;
                        this._appUrls.appBasePortalHost = response.data.appUrls.appBasePortalHost;
                        this._appAuth.authClientId = response.data.appAuth.authClientId;
                        this._appAuth.authAuthority = response.data.appAuth.authAuthority;
                        this._appAuth.authScope = response.data.appAuth.authScope;
                        this._appAuth.authScopeGraph = response.data.appAuth.authScopeGraph;
                        this._appAuth.authRedirect = response.data.appAuth.authRedirect;
                        this._appAuth.authGraphUrl = response.data.appAuth.authGraphUrl;
                        return true;
                    } else {
                        return false;
                    }
                })
                .catch((e) => {
                    throw e;
                });
        },
    },
});