const newsRoutes = [
  {
    path: '/news',
    name: 'news',
    redirect: '/news/dashboard',
    beforeEnter() {
    },
    components: {
      content: () => import('../views/News.vue'),
    },
    meta: {
      title: 'News',
      requiresAuth: true,
      accessObject: null,
    },
    children: [
      {
        path: 'dashboard',
        name: 'dashboard-news',
        components: {
          newsContent: () => import('@components/partials/news/NewsCarousel.vue'),
        },
        meta: {
          title: 'News Dashboard',
          pageH1: 'News Dashboard',
          requiresAuth: true,
          accessObject: null,
        },
      },
      {
        path: 'all',
        name: 'all-news',
        components: {
          newsContent: () => import('@components/partials/news/AllNewsCards.vue'),
        },
        meta: {
          title: 'All News',
          pageH1: 'All News',
          requiresAuth: true,
          accessObject: null,
        },
      },
    ],
  },

];

export default newsRoutes;
