<template>
  <div>
    <v-app
    >
      <v-card
        position="absolute"
        style="z-index: initial; overflow-y: auto;"
        height="100%"
        width="100%"
      >
        <v-card
          style="min-height: 100%;"
          class="compass-main-background"
        >
          <v-app-bar
            color="white"
            :elevation="1"
          >
            <template #prepend>
              <router-link
                  to="/home"
                  class="sidenav"
                >
                  <img src="@/assets/claim-doc-logo-small.png" class="header-image">
                </router-link>
            </template>
          </v-app-bar>
          <v-main
            class="m-4"
          >
            <slot></slot>
          </v-main>
        </v-card>
      </v-card>
    </v-app>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useUserStore } from '@/store/pinia/user';

export default {
  name: 'NavigationContainerNoAuth',
  components: {
  },
  props: {
  },
  setup() {
    const userStore = useUserStore();

    const appName = computed(() => {
      return userStore.appName;
    });

    return {
      appName,
    };
  },
};
</script>

<style scoped>
.header-image {
  width: 150px;
}
.header-mark {
  width: 75px;
}
.reminder-icon {
  font-size: 10px;
  padding-right: 10px;
  color: red;
}
.reminder-icon-active {
  color: red;
}
</style>