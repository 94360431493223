
import { useApplicationSettingsStore } from '../store/pinia/applicationSettings';
import { useToggleStore } from '../store/pinia/toggles';


const routes = [
  {
    path: '/nominations',
    name: 'nominations',
    redirect: { name: 'nominations-dashboard'},
    beforeEnter() {
      const applicationSettingsStore = useApplicationSettingsStore();
      const toggleStore = useToggleStore();
      if (toggleStore.featureFlags["Nominations 2.0"] === false) {
        location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/nominations');
      }
    },
    components: {
      content: () => import('../views/Nominations.vue'),
    },
    meta: {
      title: 'Nominations',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
      pageH1: 'Nominations',
    },
    children: [
      {
        path: '/nominations/dashboard',
        name: 'nominations-dashboard',
        beforeEnter() {
          const applicationSettingsStore = useApplicationSettingsStore();
          const toggleStore = useToggleStore();
          if (toggleStore.featureFlags["Nominations 2.0"] === false) {
            location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/nominations');
          }
        },
        components: {
          nomContent: () => import('@components/partials/nominations/NominationsDashboard.vue'),
        },
        meta: {
          title: 'Nominations Dashboard',
          pageH1: 'Nominations Dashboard',
          requiresAuth: true,
          
      },
    },
    {
      path: 'reconcile',
      name: 'nominations-to-reconcile',
      redirect: { name: 'nomination-to-reconcile-mine' },
      components: {
        nomContent: () => import('@components/partials/nominations/NominationsToReconcile.vue'),
      },
      meta: {
        title: 'Nominations To Reconcile',
        pageH1: 'Nominations To Reconcile',
        requiresAuth: true,
        accessObject: null, // null means all authenticated users have access
      },
      children: [{
        path: 'unassigned',
        name: 'nomination-to-reconcile-unassigned',
        components: {
        },
        meta: {
          title: 'Nominations To Reconcile - Unassigned',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
          pageH1: 'Nominations To Reconcile',
        },
      },
      {
        path: 'assigned',
        name: 'nomination-to-reconcile-assigned',
        components: {
        },
        meta: {
          title: 'Nominations To Reconcile - Assigned',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
          pageH1: 'Nominations To Reconcile',
        },
      },
      {
        path: 'provider-relations',
        name: 'nomination-to-reconcile-provider-relations',
        components: {
        },
        meta: {
          title: 'Nominations To Reconcile - Provider Relations',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
          pageH1: 'Nominations To Reconcile',
        },
      },
      {
        path: 'complete',
        name: 'nomination-to-reconcile-complete',
        components: {
        },
        meta: {
          title: 'Nominations To Reconcile - Complete',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
          pageH1: 'Nominations To Reconcile',
        },
      },
      {
        path: 'closed',
        name: 'nomination-to-reconcile-closed',
        components: {
        },
        meta: {
          title: 'Nominations To Reconcile - Closed',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
          pageH1: 'Nominations To Reconcile',
        },
      },
      {
        path: 'all',
        name: 'nomination-to-reconcile-all',
        components: {
        },
        meta: {
          title: 'Nominations To Reconcile - All',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
          pageH1: 'Nominations To Reconcile',
        },
      },
      {
        path: 'mine',
        name: 'nomination-to-reconcile-mine',
        components: {
        },
        meta: {
          title: 'Nominations To Reconcile - Mine',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
          pageH1: 'Nominations To Reconcile',
        },
      },
      {
        path: 'member-support',
        name: 'nomination-to-reconcile-member-support',
        components: {
        },
        meta: {
          title: 'Nominations To Reconcile - Member Support',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
          pageH1: 'Nominations To Reconcile',
        },
      },
      {
        path: 'soma',
        name: 'nomination-to-reconcile-soma',
        components: {
        },
        meta: {
          title: 'Nominations To Reconcile - SOMA',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
          pageH1: 'Nominations To Reconcile',
        },
      },
      {
        path: 'sc',
        name: 'nomination-to-reconcile-sc',
        components: {
        },
        meta: {
          title: 'Nominations To Reconcile - SC',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
          pageH1: 'Nominations To Reconcile',
        },
      }],
    },
    ],
  },
  {
    path: '/nominations/:nominationId',
    name: 'individual-nomination',
    beforeEnter(to) {
      const applicationSettingsStore = useApplicationSettingsStore();
      const toggleStore = useToggleStore();
      if (toggleStore.featureFlags["Nominations 2.0"] === false) {
        location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + `/nominations/${to.params.id}`);
      }
    },
    components: {
      content: () => import('../views/NominationDetails.vue'),
    },
    props: true,
    meta: {
      title: 'Nominations',
      requiresAuth: true,
      accessObject: null,
      pageH1: 'Nomination Details',
    },
  },
];

export default routes;
