import { get, post, getAndHandleMessage } from './apiRequest';
import router from '../router/index';
import { useUserStore } from '../store/pinia/user';


// eslint-disable-next-line no-unused-vars
export async function masqueradeAsUser(event) {
  const data = {
    masqueradeId: event.masqueradeId,
  };
  return data;
}

export async function revertMasquerade() {
  const data = {
    masqueradeId: null,
  };
  return data;
}

export async function getAllUserTypes() {
  return await getAndHandleMessage('/user/allUserTypes');
}


export async function getAllUserDesignations() {
  const response = await getAndHandleMessage('/user/allDesignations');
  return response.data;
}

export async function getAllGroupDesignations() {
  let data = [];

  const payload = {
    url: '/admin/users/groupdesignations',
    method: 'GET',
  };
  try {
    data = await post('/Shim', payload);
    console.log('data', data);
  } catch (error) {
    throw error;
  }

  return {
    data,
  };

}


export async function getMasqueradeTargets() {
  let data = [];
  const userStore = useUserStore();
  const resp = await userStore.getAllActiveCompassUsers();

  // Ensure data is an array before proceeding
  if (!Array.isArray(resp.data)) {
    console.error('Expected an array, but received:', (resp.data));
    return []; // Return an empty array or handle as needed
  }

  console.log('resp.data', resp.data);
  // Proceed with mapping if data is an array
  data = resp.data.map(item => ({
    name: item.displayName,
    id: item.id,
  }));
  return data;
}


export async function loadUser() {
  let userName = '';
  let userId = null;
  let fullName = '';
  let emailAddress;
  let userTypeId;
  let azureObjectId;
  let designationGroupIds;
  let viewRestricted;
  let archived;
  let showDashboard;
  let assignedToIds = [];
  let serviceCoordinatorIds = [];
  let hrLiaisonIds = [];
  let implementationManagerIds = [];
  let implementationCoordinatorIds = [];

  try {
    const data = await get('/user/working', null);
    userTypeId = data.userTypeId;
    // userTypeId = null;
    // If usertype ends up being null or invalid, log the user out
    if (userTypeId === null || userTypeId === 'Invalid') {
      // Log the user out or take them to a page with a message that they need to be granted access
      router.push('/access-issue');
    } else {
      userName = data.username;
      userId = data.id;
      fullName = data.name;
      emailAddress = data.email;
      azureObjectId = data.azureObjectId;
      designationGroupIds = data.designationGroupIds;
      viewRestricted = data.viewRestricted;
      archived = data.archived;
      showDashboard = data.showDashboard;
      serviceCoordinatorIds = data.serviceCoordinatorIds;
      assignedToIds = data.assignedToIds;
      hrLiaisonIds = data.hrLiaisonIds;
      implementationManagerIds = data.implementationManagerIds;
      implementationCoordinatorIds = data.implementationCoordinator;
    }
  } catch (error) {
    throw error;
  }
  return {
    userName,
    userId,
    fullName,
    emailAddress,
    userTypeId,
    azureObjectId,
    designationGroupIds,
    viewRestricted,
    archived,
    showDashboard,
    serviceCoordinatorIds,
    assignedToIds,
    hrLiaisonIds,
    implementationManagerIds,  
    implementationCoordinatorIds,
  };
}

export async function clearUser() {
  const userName = '';
  const userId = null;
  const fullName = '';
  const emailAddress = '';
  const userTypeId = null;
  const azureObjectId = '';
  const designationGroupIds = [];
  const viewRestricted = null;
  const archived = null;
  const showDashboard = null;
  const serviceCoordinatorIds = [];
  const assignedToIds = [];
  const hrLiaisonIds = [];
  const implementationManagerIds = [];
  const implementationCoordinatorIds = [];

  return {
    userName,
    userId,
    fullName,
    emailAddress,
    userTypeId,
    azureObjectId,
    designationGroupIds,
    viewRestricted,
    archived,
    showDashboard,
    serviceCoordinatorIds,
    assignedToIds,
    hrLiaisonIds,
    implementationManagerIds,
    implementationCoordinatorIds,
  };
}

export async function getNavigationLinks() {
  const links = [
    // {
    //   'title': 'Help/Suggestions',
    //   'icon': {
    //     'style': 'color: maroon;',
    //     'class': 'fa fa-comments',
    //   },
    //   'path': '/suggestions',
    // },
    {
      'title': 'Servicing',
      'navItems': [
        {
          'title': 'Clients',
          'path': '/clients',
        },
        {
          'title': 'Members',
          'path': '/members',
        },
        {
          'title': 'Nominations',
          'path': '/nominations',
        },
        {
          'title': 'Patients',
          'path': '/patients',
        },
      ],
    },
    {
      'title': 'Providers',
      'navItems': [
        {
          'title': 'Practitioner Database',
          'path': '/doctors',
        },
        {
          'title': 'Facility Database',
          'path': '/facilities',
        },
      ],
    },
    {
      'title': 'Team',
      'navItems': [
        {
          'title': 'Account Managers',
          'path': '/accountmanagers',
        },
        {
          'title': 'Appeals Auditor',
          'path': '/appeals/auditor',
        },
        {
          'title': 'Appeals Specialist',
          'path': '/appeals/specialist',
        },
        {
          'title': 'Appeals Admin',
          'path': '/appeals/admin',
        },
        {
          'title': 'Member Advocates',
          'path': '/memberadvocates',
        },
        {
          'title': 'Provider Relations',
          'path': '/providerrelations',
        },
      ],
    },
    {
      'title': 'Misc',
      'navItems': [
        {
          'title': 'Calendar',
          'path': "/calendar/{$userId}",
        },
        {
          'title': 'Call Guides',
          'path': 'callGuides',
        },
        {
          'title': 'Reports',
          'path': '/reports',
        },
        {
          'title': 'Site Search',
          'path': '/reports',
        },
      ],
    },
  ];

  // const payload = {
  //   url: 'api/weatherforecast',
  //   method: 'Get',
  // };
  try {
    // const data = await get('/debug/user', null);
  } catch (error) {
    throw error;
  }
  return {
    links,
  };
}

export default {
  masqueradeAsUser,
  revertMasquerade,
  getMasqueradeTargets,
  getAllUserTypes,
  loadUser,
  getAllUserDesignations,
  clearUser,
  getNavigationLinks,
};
