import { useUserStore } from '../store/pinia/user';
import { useMasqueradeStore } from '../store/pinia/masquerade';
import { useApplicationSettingsStore } from '../store/pinia/applicationSettings';
import { getToken } from './authenticate';
import { logEvent } from '../helpers/logging';
import { useToast } from 'vue-toastification';
const toast = useToast();

async function callAndHandleMessage(method, path, data) {
  const applicationSettingsStore = useApplicationSettingsStore();
  const rootURL = applicationSettingsStore.appUrls.appBaseApiHost;

  const userStore = useUserStore();
  const masqueradeStore = useMasqueradeStore();
  const token = await getToken();
  const correlationId = await userStore.correlationId || '';

  const headers = {
    'authorization': `Bearer ${token}`,
    'content-type': 'application/json',
    'x-correlation-id': correlationId,
  };
  const masqueradeId = masqueradeStore.masqueradeId;
  if (masqueradeId && masqueradeId !== null) {
    headers['masqueradeid'] = masqueradeId;
  }

  const options = {
    method,
    headers,
  };

  if (data) {
    method === 'get'
      ? path += `?${new URLSearchParams(data).toString()}`
      : options['body'] = JSON.stringify(data);
  }
  const response = await fetch(`${rootURL}${path}`, options)
    .then((response) => {
      if (response.status >= 400 && response.status < 500) {
        return undefined;
      }
      return response.text();
    })
    .then((text) => {
      return text ? JSON.parse(text) : {
        data: null,
        userMessage: "Unable to send request",
        isSuccess: false,
        links: {},
      };
    });

  if (response.userMessage) {
    if (response.isSuccess == false) {
      toast.error(response.userMessage);
    } else {
      toast.success(response.userMessage);
    }
  }
  return response;
}

// eslint-disable-next-line no-unused-vars
async function call(method, path, data) {
  const applicationSettingsStore = useApplicationSettingsStore();
  const rootURL = applicationSettingsStore.appUrls.appBaseApiHost;

  const userStore = useUserStore();
  const masqueradeStore = useMasqueradeStore();
  const token = await getToken();
  const correlationId = await userStore.correlationId || '';

  const headers = {
    'authorization': `Bearer ${token}`,
    'content-type': 'application/json',
    'x-correlation-id': correlationId,
  };
  const masqueradeId = masqueradeStore.masqueradeId;
  if (masqueradeId && masqueradeId !== null) {
    headers['masqueradeid'] = masqueradeId;
  }

  const options = {
    method,
    headers,
  };

  if (data) {
    method === 'get'
      ? path += `?${new URLSearchParams(data).toString()}`
      : options['body'] = JSON.stringify(data);
  }
  return fetch(`${rootURL}${path}`, options)
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then((text) => {
      return text ? JSON.parse(text) : {};
    })
    .then((response) => {
      return response.data;
    });
}

// eslint-disable-next-line no-unused-vars
async function callNoAuth(method, path, data) {
  const applicationSettingsStore = useApplicationSettingsStore();
  const rootURL = applicationSettingsStore.appUrls.appBaseApiHost;

  const headers = {
    'content-type': 'application/json',
    'x-correlation-id': null,
  };

  const options = {
    method,
    headers,
  };

  if (data) {
    method === 'get'
      ? path += `?${new URLSearchParams(data).toString()}`
      : options['body'] = JSON.stringify(data);
  }

  return fetch(`${rootURL}${path}`, options)
    .then((response) => {
      if (!response.ok) {
        logEvent({
          type: 'error',
          message: `HTTP error! status: ${response.status}`,
          functionName: path,
          fileName: 'apiRequest.js',
        });
        //throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.text();
    })
    .then((text) => {
      return text ? JSON.parse(text) : {};
    });
}

export async function getAndHandleMessage(path, data) {
  return await callAndHandleMessage('get', path, data);
}
export async function putAndHandleMessage(path, data) {
  return await callAndHandleMessage('put', path, data);
}
export async function postAndHandleMessage(path, data) {
  return await callAndHandleMessage('post', path, data);
}
export async function deleteAndHandleMessage(path, data) {
  return await callAndHandleMessage('delete', path, data);
}
export async function get(path, data) {
  return await call('get', path, data);
}
export async function getNoAuth(path, data) {
  return callNoAuth('get', path, data)
    .then((response) => {
      return response.data;
    });
}
export async function put(path, data) {
  return call('put', path, data);
}
export async function post(path, data) {
  return call('post', path, data);
}

export default {
  get,
  put,
  post,
  getAndHandleMessage,
  putAndHandleMessage,
  postAndHandleMessage,
  getNoAuth,
};
