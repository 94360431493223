import { createRouter, createWebHistory } from 'vue-router';
import { VueNavigationClient } from './helpers';
import { logEvent }from '../helpers/logging';
import { doesHaveAccessToPage } from '../auth/authorization';
import nominationsRoutes from './nominations';
import accountManagersRoutes from './accountManagers';
import memberAdvocatesRoutes from './memberAdvocates';
import clientsRoutes from './clients';
import membersRoutes from './members';
import patientsRoutes from './patients';
import adminRoutes from './admin';
import amSettingsRoutes from './amSettings';
import providerrelationsRoutes from './providerRelations';
import reportsRoutes from './reports';
import miscRoutes from './misc';
import reminderRoutes from './reminders';
import newsRoutes from './news';
import badRequestRoutes from './badRequest';
import facilitiesRoutes from './facilities';
import appealsRoutes from './appeals';
import practitionersRoutes from './practitioners';
import featureToggleRoutes from './toggles';
import { useAuthenticateStore } from '../store/pinia/authenticate';
import { useUserStore } from '../store/pinia/user';
import { useMasqueradeStore } from '../store/pinia/masquerade';

const unmatched = '/:pathMatch(.*)*';

const routes = [
  {
    path: '/login',
    name: 'login',
    components: {
      unguardedContent: () => import('../views/Login.vue'),
    },
    meta: {
      title: 'Login',
      requiresAuth: false,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    components: {
      unguardedContent: () => import('../views/Logout.vue'),
    },
    meta: {
      title: 'Logout',
      requiresAuth: false,
    },
  },
  ...adminRoutes,
  ...amSettingsRoutes,
  ...nominationsRoutes,
  ...reminderRoutes,
  ...accountManagersRoutes,
  ...memberAdvocatesRoutes,
  ...clientsRoutes,
  ...membersRoutes,
  ...newsRoutes,
  ...patientsRoutes,
  ...providerrelationsRoutes,
  ...reportsRoutes,
  ...miscRoutes,
  ...facilitiesRoutes,
  ...appealsRoutes,
  ...practitionersRoutes,
  ...badRequestRoutes,
  ...featureToggleRoutes,
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Hook MSAL into the router
const client = new VueNavigationClient(router);

// set up auth and guard routes
router.beforeEach(async (to, from, next) => {
  const simplifiedToPath = { path: to.path, name: to.name };
  const simplifiedFromPath = { path: from.path, name: from.name };

  // Log the route change for verbose logging
  logEvent({
    type: 'verbose',
    message: `Called: to, from: ${JSON.stringify(simplifiedToPath)} ${JSON.stringify(simplifiedFromPath)}`,
    functionName: 'beforeEach',
    fileName: 'router/index.js',
  });

  // 404, so don't complete any additional auth logic
  if (to.matched[0]?.path === unmatched) {
    return next();
  }

  // If the route requires auth, then we need to check if the user is authenticated
  if (to.meta.requiresAuth) {
    const authenticateStore = useAuthenticateStore();
    const userStore = useUserStore();
    const masqueradeStore = useMasqueradeStore();

    // Is user initialized? If not, then we need to initialize them
    if (!authenticateStore.initialized) {
      try {
      logEvent({
        type: 'verbose',
        message: 'Router beforeEach - Initialized Start',
        functionName: 'beforeEach',
        fileName: 'router/index.js',
      });
      // Set auth in progress to true so we don't try to initialize again
      await authenticateStore.setAuthInProgress(true);
      // Initialize the user
      await authenticateStore.initialize(client);
      logEvent({
        type: 'verbose',
        message: 'Router beforeEach - Initialized End',
        functionName: 'beforeEach',
        fileName: 'router/index.js',
      });
      } catch (err) {
        logEvent({
          type: 'error',
          message: 'Router beforeEach - Initialized Error',
          functionName: 'beforeEach',
          fileName: 'router/index.js',
        });
      }
    }

    // User is authorized if we have an account object
    if (authenticateStore.account) {
      try {
        logEvent({
          type: 'verbose',
          message: `Authorized Start: ${JSON.stringify(authenticateStore.account)}`,
          functionName: 'beforeEach',
          fileName: 'router/index.js',
        });
        // Check if the token is expired
        const currentTimestamp = Math.floor(Date.now() / 1000); // current time in seconds
        const tokenExpirationTimestamp = authenticateStore.account.idTokenClaims.exp;
        // If the token is expired, refresh it
        if (currentTimestamp > tokenExpirationTimestamp) {
          // Token is expired
          // Refresh the token here
          await authenticateStore.refreshToken();
          logEvent({
            type: 'verbose',
            message: `Refreshed Token: ${JSON.stringify(authenticateStore.account)}`,
            functionName: 'beforeEach',
            fileName: 'router/index.js',
          });
        }
        // Check if we have a user photo, and if not, go get it
        // This is done without await because the photo is not needed to continue
        // And this call can take time to complete which will slow down the app
        if (userStore.userDetails.userPhoto === null) {
          // We don't need to await this because we don't need the photo to continue
          userStore.getUserPhoto();
        }
        // Check if we have a correlationId, and if not, create it
        if (userStore.correlationId === null || userStore.correlationId === undefined) {
          logEvent({
            type: 'verbose',
            message: 'Create CorrelationId Requested',
            functionName: 'beforeEach',
            fileName: 'router/index.js',
          });
          // We don't need to await this because we don't need the correlationId to continue
          userStore.createNewCorrelationId();
        }
        logEvent({
          type: 'verbose',
          message: `Authorized End: ${JSON.stringify(authenticateStore.account)}`,
          functionName: 'beforeEach',
          fileName: 'router/index.js',
        });
        // Check if we have user details, and if not, go get them
        // We need to await the response because these details are needed for some api calls
        // Because we're storing in state and not persisting to localStorage
        // This is going to be cleared anytime they open a new tab or refresh the screen
        if (userStore.userDetails.userId === null || userStore.userDetails.userName === '' || userStore.userDetails.fullName === '') {
            // Load the user details
            await userStore.loadUser();
            // Load the masquerade details
            await masqueradeStore.loadUser();
        }
        // Set auth in progress to false
        await authenticateStore.setAuthInProgress(false);

        // Check if the user has access to the page they are trying to view
        const doesHaveAccess =  await doesHaveAccessToPage(to.name, to.meta.accessObject);
        if (doesHaveAccess === false || doesHaveAccess === undefined) {
          logEvent({
            type: 'verbose',
            message: `Blocked access to page: ${to.name} - ${userStore.userDetails.userAccess.userTypeId} is NOT in ${to.meta.accessObject}`,
            functionName: 'beforeEach',
            fileName: 'router/index.js',
          });
          return next({ path: '/unable-to-access', query: { pageToAccess: to.fullPath } });
        } else {
          // If user doesn't have access, log it
          let message = '';
          if (to.meta.accessObject) {
            if (masqueradeStore.masqueradeId) {
              message = `Authorized access to page: ${to.name} - ${masqueradeStore.userDetails.userAccess.userTypeId} is in ${JSON.stringify(to.meta.accessObject)}`;
            } else {
              message = `Authorized access to page: ${to.name} - ${userStore.userDetails.userAccess.userTypeId} is in ${JSON.stringify(to.meta.accessObject)}`;
            }
          } else {
            message = `Authorized access to page: ${to.name} - All authenticated users are allowed to access this page`;
          }

          logEvent({
            type: 'verbose',
            message,
            functionName: 'beforeEach',
            fileName: 'router/index.js',
          });
        }
        return next();
      } catch (err) {
        await authenticateStore.setAuthInProgress(false);
        logEvent({
          type: 'error',
          message: `Authorized Error: ${err.message} for account ${JSON.stringify(authenticateStore.account)}`,
          functionName: 'beforeEach',
          fileName: 'router/index.js',
        });
        return next({ path: '/access-issue' });
      }
    }

    // initialized but not yet authorized
    try {
      logEvent({
        type: 'verbose',
        message: `Login Start: ${JSON.stringify(authenticateStore.account)}`,
        functionName: 'beforeEach',
        fileName: 'router/index.js',
      });
      try {
        logEvent({
          type: 'verbose',
          message: 'Create CorrelationId Requested',
          functionName: 'beforeEach',
          fileName: 'router/index.js',
        });
        // Generate the correlation Id
        await userStore.createNewCorrelationId();
      } catch (error) {
        // Log the error with setting the new correlation Id
        logEvent({
          type: 'error',
          message: `Failed to generate a new Correlation Id: ${error.message}`,
          functionName: 'beforeEach',
          fileName: 'router/index.js',
        });
      }
      // Attempt to login
      await authenticateStore.login();
    }
    catch (err) {
      logEvent({
        type: 'error',
        message: `Login Error: ${JSON.stringify(authenticateStore.account)}`,
        functionName: 'beforeEach',
        fileName: 'router/index.js',
      });
      await authenticateStore.setAuthInProgress(false);
      return next(false);
    }
  }
  // If the route does not require auth, then we can just move on
  next();
});

export default router;
