import { useApplicationSettingsStore } from '../store/pinia/applicationSettings';
import { useToggleStore } from '../store/pinia/toggles';

const routes = [
  {
    path: '/facilitydb',
    name: 'facilitydb',
    redirect: '/facilities',
    beforeEnter() {
      const applicationSettingsStore = useApplicationSettingsStore();
      const toggleStore = useToggleStore();
      if (toggleStore.featureFlags["Facilities 2.0"] === false) {
        location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/facilities');
      }
    },
    components: {
      content: () => import('../views/Facilities.vue'),
    },
    meta: {
      title: 'Facility DB',
      pageH1: 'Facility Search',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
    children: [{
      path: '/facilities',
      name: 'facilities',
      components: {
        facilityContent: () => import('@components/partials/facilities/FacilitySearch.vue'),
      },
      meta: {
        title: 'Facility Search',
        pageH1: 'Facility Search',
        requiresAuth: true,
        accessObject: null, // null means all authenticated users have access
      },
    }],
  },
  {
    path: '/facilities/:facilityId',
    name: 'facility-details',
    beforeEnter(to) {
      const applicationSettingsStore = useApplicationSettingsStore();
      location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + `/facilities/edit/${to.params.facilityId}`);
    },
    components: {
      content: () => import('../views/Facilities.vue'),
    },
    meta: {
      title: 'Facility Details',
      pageH1: 'Facility Details',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
  },
];

export default routes;
