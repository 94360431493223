import { useApplicationSettingsStore } from '../store/pinia/applicationSettings';
import { useUserStore } from '../store/pinia/user';

const routes = [
  {
    path: '/',
    name: 'dashboard',
    redirect: '/home',
    components: {
    },
    meta: {
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
  },
  {
    path: '/home',
    name: 'home',
    components: {
      content: () => import('../views/Dashboard.vue'),
    },
    meta: {
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
  },
  {
    path: '/suggestions',
    name: 'suggestions',
    beforeEnter() {
      const applicationSettingsStore = useApplicationSettingsStore();
      location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/suggestions');
    },
    components: {
    },
    meta: {
      title: 'Help/Suggestions',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
  },
  {
    path: '/search',
    name: 'search',
    beforeEnter() {
      const applicationSettingsStore = useApplicationSettingsStore();
      location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/search');
    },
    components: {
    },
    meta: {
      title: 'Search',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
  },
  {
    path: '/allprovidersdb',
    name: 'allprovidersdb',
    beforeEnter() {
      const applicationSettingsStore = useApplicationSettingsStore();
      location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/allproviders');
    },
    components: {
    },
    meta: {
      title: 'All Providers DB',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    beforeEnter() {
      const userStore = useUserStore();
      const applicationSettingsStore = useApplicationSettingsStore();
      location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + `/calendar/${userStore.userDetails.userId}`);
    },
    components: {
    },
    meta: {
      title: 'Reports',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
  },
  {
    path: '/callGuides',
    name: 'callguides',
    beforeEnter() {
      const applicationSettingsStore = useApplicationSettingsStore();
      location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/clients/callguides');
    },
    components: {
    },
    meta: {
      title: 'Call Guides',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
  },
];

export default routes;
