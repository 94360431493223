import { writeFile, utils } from 'xlsx';
import moment from 'moment';

// Used to convert a blob to base64 encoded string
export async function convertBlobToBase64(blob) {
  if (blob !== undefined) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onerror = reject;
      reader.onload = () => {
        // Remove the prefix
        const base64String = reader.result.replace(/^data:.+;base64,/, '');
        resolve(base64String);
      };
      reader.readAsDataURL(blob);
    });
  } else {
    return undefined;
  }
}

// Used to check whether a string is valid json
export async function isStringValidJSON(value) {
  let stringToValidate = typeof value !== 'string' ? JSON.stringify(value) : value;
  try {
    stringToValidate = JSON.parse(stringToValidate);
  } catch (error) {
    return false;
  }
  return typeof stringToValidate === 'object' && value !== null;
}

// Used to check whether a string is valid json
export async function extractPathFromURL(value) {
  // eslint-disable-next-line no-useless-escape
  const regex = /^https?:\/\/[^\/]+(\/.*)$/;

  try {
    const match = value.match(regex);
    return match ? match[1] : null;
  } catch (error) {
    return null;
  }
}

// Takes in a dashed string and converts it to the camel casing version
export async function convertDashedStringToCamelCasing(value) {
  return value
    .split('-')
    .reduce((a, b) => a + b.charAt(0).toUpperCase() + b.slice(1));
}

// Takes in a dashed string and converts it so the first letter of each word is uppercase
export async function convertDashedStringToFirstCharUpper(value) {
  return value
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(''); // Join with a space to separate words
}

// Takes in a string with uppercase letters in the beginning of each word
// And returns a lowercase string with a hyphen separating each word
export async function convertDashedStringToKebabCase(value) {
  return value.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
}

// Generates a random guid
export function generateGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}

export function formatDataForExport(data, columns) {
  return data.map(row => {
    const formattedRow = {};
    columns.forEach(column => {
      formattedRow[column.title] = row[column.key];
    });
    return formattedRow;
  });
}

// Takes in JSON and exports to Excel
export function exportToExcel(data, columns, inputFileName) {
  // Format the data per the columns
  const formattedData = formatDataForExport(data, columns);
  // Create a new workbook and add a worksheet
  const workbook = utils.book_new();
  const worksheet = utils.json_to_sheet(formattedData);

  // Append the worksheet to the workbook
  utils.book_append_sheet(workbook, worksheet, "Nominations");

  // Generate file name
  const dateForFile = new Date();
  const fileName = dateForFile.getFullYear().toString()
    + '-'
    + (dateForFile.getMonth() + 1).toString().padStart(2, '0')
    + '-'
    + dateForFile.getDate().toString().padStart(2, '0')
    + '_'
    + inputFileName
    + '.xlsx';

  // Write the workbook to a file and save it
  writeFile(workbook, fileName);
}

export function formatDateForNews(date) {
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return new Date(date).toLocaleDateString(undefined, options);
}

export function formatPhoneNumber(phoneNumberString, extension) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const formattedPhone =  '(' + match[1] + ') ' + match[2] + '-' + match[3];
    if (extension !== null && extension !== undefined) {
      return `${formattedPhone} Ext. ${extension}`;
    }
    return formattedPhone;
  }
  return null;
}

export function formatPhoneNumberLink(phoneNumberString, extension) {
  if (extension !== null && extension !== undefined) {
    return `tel:+${phoneNumberString}p${extension}`;
  }
  return `tel:+${phoneNumberString}`;
}

export function formatDateOfBirth(dateOfBirth) {
  const dateParts = dateOfBirth.split('T')[0].split('-'); // Extract only the date part
  if (dateParts.length === 3) {
    const [year, month, day] = dateParts;
    return `${month}/${day}/${year}`;
  }
}

export function isValidDate(value) {
  if (value && value.length === 10) {
    const parts = value.split('/');
    value = `${parts[2]}-${parts[0]}-${parts[1]}`; // restructure to yyyy-mm-dd
    const date = moment(value, 'YYYY-MM-DD');
    if (date.isValid()) {
      return date._i;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export function getTimeZone(timeZone) {
  const timeZoneMap = {
    'CST': 'America/Chicago',
    'EST': 'America/New_York',
    'PST': 'America/Los_Angeles',
    'MST': 'America/Denver',
    'AKST': 'America/Anchorage',
    'HST': 'Pacific/Honolulu',
    'AST': 'America/Puerto_Rico',
    'CHST': 'Pacific/Guam',
    'SST': 'Pacific/Pago_Pago',
    'UTC': 'Etc/UTC',
    // Add more mappings as needed
  };

  // Map abbreviation to IANA time zone name if necessary
  return timeZoneMap[timeZone] || timeZone;
}

export default {
  convertBlobToBase64,
  isStringValidJSON,
  extractPathFromURL,
  convertDashedStringToCamelCasing,
  convertDashedStringToFirstCharUpper,
  convertDashedStringToKebabCase,
  generateGUID,
  exportToExcel,
  formatPhoneNumber,
  formatPhoneNumberLink,
  isValidDate,
  getTimeZone,
};