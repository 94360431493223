import { getNoAuth } from './apiRequest';

export async function getApplicationSettings() {
  let data = [];
  try {
    data = await getNoAuth('/UI/settings');
  } catch (error) {
    throw error;
  }

  return {
    data,
  };
}

export default {
  getApplicationSettings,
};
