import * as Sentry from '@sentry/vue';
import { useUserStore } from '@/store/pinia/user';

// Used to wrap our logging provider
// This makes it easier to replace our logging platform
// rather than having to update all references to logging
// throughout the code
export async function logEvent(logObject) {
  // Types - 'error', 'warning', 'info', 'verbose'
  const logType = logObject.type || null;
  const logMessage = logObject.message || null;
  const logFunction = logObject.functionName || null;
  const logFileName = logObject.fileName || null;
  const userStore = useUserStore();

  Sentry.setUser({ 
    id: userStore.userDetails.id, 
    username: userStore.userDetails.userName,
    email: userStore.userDetails.emailAddress,
    designations: JSON.stringify(userStore.userDetails.userAccess.designationGroupIds),
  });

  const outputFormatted = {
    type: logType,
    fileName: logFileName,
    functionName: logFunction,
    message: logMessage,
  };

  if (logType === 'error') {
    Sentry.withScope(scope => {
      scope.setExtras(outputFormatted);
      Sentry.captureException(new Error(outputFormatted.message));
    });
    console.error('Error:', outputFormatted);
  }
  if (logType === 'warning') {
    Sentry.withScope(scope => {
      scope.setExtras(outputFormatted);
      Sentry.captureMessage(outputFormatted.message, 'warning');
    });
    console.warn('Warning:', outputFormatted);
  }
  if (logType === 'info') {
    console.info('Info:', outputFormatted);
  }
  if (logType === 'verbose') {
    console.debug('Verbose:', outputFormatted);
  }
}

export default {
  logEvent,
};