import { defineStore } from 'pinia';
import {
  initialize,
  initializeMsalInstance,
  login,
  logout,
  refreshToken,
} from '../../services/authenticate';
import { logEvent } from '../../helpers/logging';

export const useAuthenticateStore = defineStore('authenticate', {
  state: () => ({
    _initialized: false,
    _account: null,
    _authInProgress: false,
    _error: '',
    _localStorageObject: {
      masqueradeId: null,
      correlationId: null,
      userPreferences: {
        leftNav: 'open',
      },
    },
  }),
  getters: {
    initialized: (state) => state._initialized,
    account: (state) => state._account,
    authInProgress: (state) => state._authInProgress,
    error: (state) => state._error,
    localStorageObject: (state) => state._localStorageObject,
  },
  actions: {
    async initializeMsalInstance(event) {
      return initializeMsalInstance(event)
        .catch((e) => {
          this._error = e.message;
          throw e;
        });
    },
    async initialize(event) {
      return initialize(event)
        .then((response) => {
          this._initialized = true;
          this._account = response;
          return response;
        })
        .catch((e) => {
          this._error = e.message;
          throw e;
        });
    },
    async login(event) {
      this._error = '';
      return login(event)
        .then((response) => {
          if (response) {
            this._account = response;
            this._error = '';
          } else {
            throw 'No response returned from auth provider.';
          }
        })
        .catch((e) => {
          this._error = e.message;
          throw e;
        });
    },
    async logout(event) {
      return logout(event)
        .then(() => {
          this._account = null;
          this._error = '';
          localStorage.clear();
          sessionStorage.clear();
        })
        .catch((e) => {
          this._error = e.message;
          throw e;
        });
    },
    async setAuthInProgress(event) {
      this._authInProgress = event;
    },
    async setLocalStorageObject(event) {
      this._localStorageObject = { ...this.localStorageObject, ...event };
      try {
        localStorage.setItem('compassAttributes', JSON.stringify(this.localStorageObject));
      } catch (error) {
        logEvent({
          type: 'error',
          message: `Error saving to localStorage, ${error.message}`,
          functionName: 'setLocalStorageObject',
          fileName: 'store/pinia/authenticate.js',
        });
      }
    },
    async refreshToken() {
      return refreshToken()
        .then((response) => {
          this._account = response;
          return response;
        })
        .catch((e) => {
          this._error = e.message;
          throw e;
        });
    },
  },
});