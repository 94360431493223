import { getGraphToken } from './authenticate';
import { convertBlobToBase64 } from '../helpers/content';
import { useApplicationSettingsStore } from '../store/pinia/applicationSettings';

export async function getImage() {
  const applicationSettingsStore = useApplicationSettingsStore();
  const rootURL = applicationSettingsStore.appAuth.authGraphUrl;

  const token = await getGraphToken();
  const options = {
    method: 'get',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'image/jpeg',
    },
  };

  return fetch(`${rootURL}/me/photo/$value`, options)
    .then((response) => {
      if (response !== null && response.ok) {
        return response.blob();
      } else {
        if (response.status === 404) {
          return null;
        } else {
          throw new Error('Profile image not found');
        }
      }
    }).then((data) => {
      return convertBlobToBase64(data);
    }).catch((error) => {
      throw error;
      // Error is ok because it generally means we don't have an avatar image
    });
}

export default {
  getImage,
};
