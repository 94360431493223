<template>
  <div>
    <v-app
    >
      <v-card
        position="absolute"
        style="z-index: initial; overflow-y: auto;"
        height="100%"
        width="100%"
      >
        <v-navigation-drawer
          v-model="drawer"
          :expand-on-hover="false"
          :permanent="true"
          :rail="!holdOpen"
          rail-width="1"
          style="min-width: 55px;"
          v-if="holdOpen"
        >
          <v-list
            nav
            density="compact"
            class="sidenav"
          >
            <v-list-item
              link
              tag="div"
              class="sidenav m-0"
            >
              <v-list-item-title
                class="font-weight-bold sidenav"
              >
                <router-link
                  to="/home"
                  class="sidenav"
                >
                  <img src="@/assets/claim-doc-logo-small.png" class="header-image">
                </router-link>
              </v-list-item-title>
            </v-list-item>
            <div
              v-for="section in validatedLinks"
              :key="section.title"
              class="pt-6"
            >
              <v-list-subheader
                class="left-nav-section-heading"
              >
                {{ section.title }}
              </v-list-subheader>
              <v-list-item
                v-for="link in section.navItems"
                :key="link.title"
                :value="link"
                class="sidenav m-0 ps-3"
                :slim="true"
                :active="isLinkSelected(link.path)"
              >
                <v-list-item-title
                  class="sidenav left-nav-section-nav-item"
                >
                  <router-link
                    :to="link.path"
                    class="sidenav"
                  >
                    {{ link.title }}
                  </router-link>
                </v-list-item-title>
              </v-list-item>
            </div>
          </v-list>
        </v-navigation-drawer>
        <v-card
          style="min-height: 100%;"
          class="compass-main-background"
        >
          <v-app-bar
            color="white"
            :elevation="1"
          >
            <v-banner
              lines="one"
              class="masquerade-banner"
              width="1050px"
              max-width="1050px"
              v-if="masqueradeId && areCurrentAndWorkingUsersDifferent"
            >
              <v-banner-text
                class="-mt-3 ms-4 banner-text"
              >
                Currently Masquerading as {{ masqueradeUser }}
              </v-banner-text>

              <v-banner-actions
                class="-mt-0.5"
              >
                <CustomButton
                  label="Stop Masquerading"
                  btnType="secondary"
                  :loading="false"
                  :disabled="false"
                  size="small"
                  class="ms-2 mb-2 stop-masquerading-button"
                  @update:buttonClick="stopMasquerading"
                ></CustomButton>
              </v-banner-actions>
            </v-banner>
            <template #prepend>
              <v-app-bar-nav-icon
                color="black"
                icon="fa fa-bars"
                class="ms-0 me-0 pt-0 pb-1"
                @click="showLeftNav"
              >
              </v-app-bar-nav-icon>
            </template>
            <template #append>
              <Userdropdown
                class="pull-right"
              />
            </template>
          </v-app-bar>
          <v-main
            class="m-4"
          >
            <slot></slot>
          </v-main>
        </v-card>
      </v-card>
    </v-app>
  </div>
</template>
<script>
import { ref, computed, onMounted } from 'vue';
import { useMasqueradeStore } from '@/store/pinia/masquerade';
import { useUserStore } from '@/store/pinia/user';
import { useRoute, useRouter } from 'vue-router';
import { logEvent } from '@/helpers/logging';
import Userdropdown from './UserDropdown.vue';
import CustomButton from '@components/interface/CustomButton.vue';

export default {
  name: 'NavigationContainer',
  components: {
    Userdropdown,
    CustomButton,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    
    const componentName = ref('NavigationContainer.vue');
    const masqueradeStore = useMasqueradeStore();
    const userStore = useUserStore();
    const drawer = ref(true);
    const links = ref([]);
    const remindersActive = ref(false);
    const masqueradeId = computed(() => {
      return masqueradeStore.masqueradeId;
    });
    const masqueradeUser = computed(() => {
      return masqueradeStore.userDetails.fullName + ' (' + masqueradeStore.userDetails.azureObjectId + ')';
    });
    const areCurrentAndWorkingUsersDifferent = computed(() => {
      const workingUserAzureObjectId = masqueradeStore.userDetails.azureObjectId;
      const currentUserAzureObjectId = userStore.userDetails.azureObjectId;
      return workingUserAzureObjectId !== currentUserAzureObjectId;
    });
    const appName = computed(() => {
      return userStore.appName;
    });
    const validatedLinks = computed(() => {
      return links.value;
    });
    const holdOpen = computed(() => {
      if (userStore.userPreferences.leftNav === 'open') {
        return true;
      } else {
        return false;
      }
    });

    async function showLeftNav () {
      if (holdOpen.value === true) {
        userStore.updateLeftNav('closed');
      } else {
        userStore.updateLeftNav('open');
      }
    }

    async function getLinks() {
      try {
        await userStore.getNavigationLinks()
          .then((response) => {
            links.value = response.links;
          })
          .catch((error) => {
            const logObject = {
              type: 'error',
              message: `${error.name}: ${error.message} - 1`,
              fileName: componentName.value,
              functionName: 'getLinks',
            };
            logEvent(logObject);
          });
      } catch (error) {
        const logObject = {
          type: 'error',
          message: `${error.name}: ${error.message} - 2`,
          fileName: componentName.value,
          functionName: 'getLinks',
        };
        logEvent(logObject);
      }
    }

    // Used to highlight the left navigation item
    function isLinkSelected(linkPath) {
      return route.matched.some(record => record.path === linkPath);
    }

    // Stop masquerading
    async function stopMasquerading() {
      await masqueradeStore.revertMasquerade();
      forceReload();
    }

    async function forceReload() {
      router.go();
    }

    onMounted(() => {
      getLinks();
    });

    return {
      drawer,
      holdOpen,
      window,
      validatedLinks,
      showLeftNav,
      appName,
      remindersActive,
      isLinkSelected,
      masqueradeUser,
      stopMasquerading,
      masqueradeId,
      areCurrentAndWorkingUsersDifferent,
    };
  },
};
</script>

<style scoped>
.header-image {
  width: 150px;
}
.header-mark {
  width: 75px;
}
.reminder-icon {
  font-size: 10px;
  padding-right: 10px;
  color: red;
}
.reminder-icon-active {
  color: red;
}
.masquerade-banner {
  background-color: #BF382E;
  border-radius: 4px;
  color: #ffffff;
  height: 45px;
}
.stop-masquerading-button {
  border: 1px solid #ffffff;
  border-radius: 4px;
}
.banner-text {
  width: 800px;
}
</style>