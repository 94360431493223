import { NavigationClient } from '@azure/msal-browser';

// Override MSAL Route Navigation
export class VueNavigationClient extends NavigationClient {
  constructor (router) {
    super();
    this.router = router;
  }
  // Only called during redirects
  navigateExternal(url, options) {
    return super.navigateExternal(url, options);
  }
  // Navigates to other pages in the app
  async navigateInternal(url, options) {
    const path = url.replace(window.location.origin, '');
    if (options.noHistory) {
      await this.router.replace(path);
    } else {
      await this.router.push(path);
    }
    return false;
  }
}