const routes = [
  {

    path: '/accountmanagers/settings',
    name: 'amdashboard',

    meta: {
      title: 'Account Manager Dashboard',
      pageH1: 'Account Manager Dashboard',
      requiresAuth: true,
      // will probs need to change to make admin only view
      accessObject: [
        {
          accessTypeFunction: 'isAtLeastServiceCoordinator',
          designations: null,
        },
      ],
    },
    beforeEnter() {
    },
    components: {
      content: () => import('../views/AccountManagersDashboard.vue'),
    },


  },
];

export default routes;
