import { DesignationGroupId } from '../autogenerated/api.generated.clients';
import { useApplicationSettingsStore } from '../store/pinia/applicationSettings';
import { useToggleStore } from '../store/pinia/toggles';

const routes = [
  {
    path: '/members',
    name: 'members',
    redirect: { name: 'members-dashboard' },
    beforeEnter() {
      const applicationSettingsStore = useApplicationSettingsStore();
      const toggleStore = useToggleStore();
      if (toggleStore.featureFlags["Members 2.0"] === false) {
        location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/members');
      }
    },
    components: {
      content: () => import('../views/Members.vue'),
    },
    meta: {
      title: 'Members',
      PageH1: 'Members',
      requiresAuth: true,
      accessObject: [{
        designations: [DesignationGroupId.MemberView],
      }],
    },
    children: [{
      path: '/members/dashboard',
      name: 'members-dashboard',
      beforeEnter() {
        const applicationSettingsStore = useApplicationSettingsStore();
        const toggleStore = useToggleStore();
        if (toggleStore.featureFlags["Members 2.0"] === false) {
          location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/members');
        }
      },
      components: {
        membersContent: () => import('@components/partials/members/MemberDashboard.vue'),
      },
      meta: {
        title: 'Members Dashboard',
        PageH1: 'Members',
        requiresAuth: true,
        accessObject: [{
          designations: [DesignationGroupId.MemberView],
        }],
      },
    },
   ],
  },
  {
    path: '/members/:memberId',
    name: 'individual-member',
    beforeEnter(to) {
      const applicationSettingsStore = useApplicationSettingsStore();
      const toggleStore = useToggleStore();
      if (toggleStore.featureFlags["Members 2.0"] === false) {
        location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + `/members/${to.params.id}`);
      }
    },
    components: {
      content: () => import('../views/MemberDetails.vue'),
    },
    props: true,
    meta: {
      title: 'Member Details',
      requiresAuth: true,
      accessObject: [{
        designations: [DesignationGroupId.MemberView],
      }],
    },
  },
  {
    path: '/member-nominations/:id', // This needs to change to a filtered nomination screen when the feature is ready
    name: 'member-nomination',
    beforeEnter(to) {
      const applicationSettingsStore = useApplicationSettingsStore();
      location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + `/members/show/${to.params.id}/nomination/new`);
    },
  },
];

export default routes;
