import { DesignationGroupId } from '../autogenerated/api.generated.clients';
import { useApplicationSettingsStore } from '../store/pinia/applicationSettings';

const routes = [
  {
    path: '/clients',
    name: 'clients',
    beforeEnter() {
      const applicationSettingsStore = useApplicationSettingsStore();
      location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/clients/list');
    },
    components: {
    },
    meta: {
      title: 'Clients',
      requiresAuth: true,
    },
    children: [{
      path: ':id',
      name: 'client-individual',
      beforeEnter(to) {
        const applicationSettingsStore = useApplicationSettingsStore();
        location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + `/clients/${to.params.id}`);
      },
      components: {},
      meta: {
        title: 'Clients',
        requiresAuth: true,
        accessObject: [
          {
            accessTypeFunction: 'isAtLeastLeadership',
            designations: [DesignationGroupId.ClientInformationView],
          },
        ],
      },
    },
    {
      path: 'profile/:id',
      name: 'client-profile',
      beforeEnter(to) {
        const applicationSettingsStore = useApplicationSettingsStore();
        location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + `/memberadvocates/clientprofile/${to.params.id}`);
      },
      components: {},
      meta: {
        title: 'Client Profile',
        requiresAuth: true,
        accessObject: [
          {
            accessTypeFunction: 'isAtLeastLeadership',
            designations: [DesignationGroupId.ClientProfileView],
          },
        ],
      },
    },
    {
      path: 'table',
      name: 'client-table',
      beforeEnter() {
        const applicationSettingsStore = useApplicationSettingsStore();
        location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/clients/table');
      },
      components: {},
      meta: {
        title: 'Client Table',
        requiresAuth: true,
        accessObject: [
          {
            accessTypeFunction: 'isAtLeastLeadership',
            designations: null,
          },
        ],
      },
    },
    {
      path: 'list',
      name: 'client-list',
      beforeEnter() {
        const applicationSettingsStore = useApplicationSettingsStore();
        location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/clients/list');
      },
      components: {},
      meta: {
        title: 'Client List',
        requiresAuth: true,
        authorizedForUserTypeId: null,
      },
    },
    ],
  },
];

export default routes;
