const routes = [
  {

    path: '/admin/settings',
    name: 'admindashboard',

    meta: {
      title: 'Admin Dashboard',
      pageH1: 'Admin Dashboard',
      requiresAuth: true,
      // will probs need to change to make admin only view
      accessObject: [
        {
          accessTypeFunction: 'isAtLeastLeadership',
          designations: null,
        },
      ],
    },
    beforeEnter() {
      // location.href = (import.meta.env.VITE_APP_BASE_LEGACY_HOST + '/nominations');
    },
    components: {
      content: () => import('../views/AdminDashboard.vue'),
    },


  },
];

export default routes;
