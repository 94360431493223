<template>
  <div>
    <div
      v-if="isUserAuthenticated"
    >
      <NavigationContainer>
        <template #default>
          <router-view
            id="content"  
            name="content"
          />
        </template>
      </NavigationContainer>
    </div>
    <div
      v-else
    >
      <NavigationContainerNoAuth>
        <router-view
          id="unguardedContent"
          name="unguardedContent"
        />
      </NavigationContainerNoAuth>
    </div>
    <div class="loading-overlay"
      v-if="isAuthInProgress"
    >
      <div>
        <i class="fa-solid fa-spinner fa-spin"></i> Authenticating your account...
      </div>
    </div>
    <!-- Error For Debugging -->
    <!-- This should be removed or commented out before going to production -->
    <div
      v-if="authError !== ''"
      id="error"
    >
      {{ authError }}
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';
import { useAuthenticateStore } from './store/pinia/authenticate';
import { useUserStore } from './store/pinia/user';
import NavigationContainer from '@components/partials/ui/NavigationContainer.vue';
import NavigationContainerNoAuth from '@components/partials/ui/NavigationContainerNoAuth.vue';

export default {
  name: 'App',
  components: {
    NavigationContainer,
    NavigationContainerNoAuth,
  },
  props: {},
  setup() {
    // eslint-disable-next-line no-unused-vars
    
    const authenticateStore = useAuthenticateStore();
    const userStore = useUserStore();
    // Get the current user account to see if the user is authenticated
    // If no user account exists, we won't show them the app yet
    const isUserAuthenticated = computed(() => {
      if (authenticateStore.account !== null && userStore.userDetails.userId !== null) {
        return true;
      }
      else {
        return false;
      }
    });

    const isAuthInProgress = computed(() => {
      return authenticateStore.authInProgress;
    });

    const authError = computed(() => {
      return authenticateStore.error;
    });

    return {
      isUserAuthenticated,
      isAuthInProgress,
      authError,
    };
  },
};
</script>

<style scoped>
#error {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  padding: 1rem;
  border: 1px solid #EEE;
  box-shadow: 0 1rem 2rem #0003;
  text-align: right;
  color: red;
}
.loading-overlay {
  z-index: 5000;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.4;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}
</style>
