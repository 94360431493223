import { useApplicationSettingsStore } from '../store/pinia/applicationSettings';

const routes = [
  {
    path: '/reports',
    name: 'reports',
    beforeEnter() {
      const applicationSettingsStore = useApplicationSettingsStore();
      location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/reports');
    },
    components: {
    },
    meta: {
      title: 'Reports',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
  },
];

export default routes;
