import { useApplicationSettingsStore } from '../store/pinia/applicationSettings';
import { useToggleStore } from '../store/pinia/toggles';

const routes = [
  {
    path: '/practitionerdb',
    name: 'practitionerdb',
    redirect: '/doctors',
    beforeEnter() {
      const applicationSettingsStore = useApplicationSettingsStore();
      const toggleStore = useToggleStore();
      if (toggleStore.featureFlags["Practitioners 2.0"] === false) {
        location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + '/doctors');
      }
    },
    components: {
      content: () => import('../views/Practitioners.vue'),
    },
    meta: {
      title: 'Practitioners',
      pageH1: 'Practitioner Search',
      requiresAuth: true,
      accessObject: null, // null means all authenticated users have access
    },
    children: [
      {
        path: '/doctors',
        name: 'doctors',
        components: {
          practitionerContent: () => import('@components/partials/practitioners/PractitionerSearch.vue'),
        },
        meta: {
          title: 'Practitioner Search',
          pageH1: 'Practitioner Search',
          requiresAuth: true,
          accessObject: null, // null means all authenticated users have access
        },
      },
    ],
  },
  {
    path: '/doctors/:id',
    name: 'practitioner-details',
    beforeEnter(to) {
      const applicationSettingsStore = useApplicationSettingsStore();
      location.href = (applicationSettingsStore.appUrls.appBaseLegacyHost + `/doctors/edit/${to.params.id}`);
    },
    components: {
      content: () => import('../views/Practitioners.vue'),
    },
    meta: {
      title: 'Practitioner Details',
      pageH1: 'Practitioner Details',
      requiresAuth: true,
      accessObject: [
        {
          accessTypeFunction: null,
          designations: null,
        },
      ],
    },
  },
];

export default routes;
