const reminderRoutes = [
  {
    path: '/reminders',
    name: 'reminders',
    redirect: '/reminders/mine',
    beforeEnter() {
    },
    components: {
      content: () => import('../views/Reminders.vue'),
    },
    meta: {
      title: 'Reminders',
      requiresAuth: true,
      accessObject: null,
    },
    children: [
      {
        path: 'mine',
        name: 'my-reminders',
        components: {
          remindersContent: () => import('../components/partials/dashboard/UpcomingReminders.vue'),
        },
        meta: {
          title: 'My Reminders',
          pageH1: 'My Reminders',
          requiresAuth: true,
          accessObject: null,
        },
      },
    ],
  },
];

export default reminderRoutes;
